/* eslint-disable react/no-danger */
import React, { FC, useEffect } from 'react';
import { Layout } from '@components/Layout';
import { LinkButton, Heading, Box } from '@components/Primitives';
import { observer } from 'mobx-react';
import { FormattedMessage as M } from 'gatsby-plugin-intl';
import styled from 'styled-components';
import { useStores } from '@stores';

interface RegisterIndexPageProps {
  location: any;
}

const OrderedList = styled.ol`
  text-align: center;
  li {
    margin-bottom: 1rem;
  }
`;

const MainHeading = styled(Heading)`
  text-align: center;
`;

const RegisterIndexPage: FC<RegisterIndexPageProps> = observer(({
  location: { origin },
}) => {
  const { authStore: { getAuthUrl, authUrl } } = useStores();
  useEffect(() => {
    getAuthUrl(`${origin}/register/validated-user/`);
  }, [authUrl]);

  const t = (id: string) => `pages.register.${id}`;

  return (
    <Layout twoColumnLayout>
      <Box pl="2" pr="2">
        <MainHeading level="3">
          <M id={t('twoPhaseRegistration')} />
        </MainHeading>

        <LinkButton mt="2" mb="2" href={authUrl}><M id={t('gotoIdentificationService')} /></LinkButton>

        <OrderedList>
          <li><M id={t('phase1')} /></li>
          <li><M id={t('phase2')} /></li>
        </OrderedList>

      </Box>
    </Layout>
  );
});

export default RegisterIndexPage;